import { FormattedMessage } from 'react-intl';

const SohBanner = () => {
    return (
        <div
            data-testid="soh-information-banner"
            className="panel-body bg-lightest border border-color-dark margin-bottom-15"
        >
            <div className={'display-flex flex-column'}>
                <div className={'display-flex justify-content-start'}>
                    <span
                        className="rioglyph rioglyph-exclamation-sign text-color-dark text-size-16
                                        width-20 margin-top-0 align-self-center"
                    />
                    <span className={'text-bold text-size-16'}>
                        <FormattedMessage id={'batteryService.stateOfHealth.availableAfterCollecting'} />
                    </span>
                </div>
                <div className={'display-flex justify-content-start'}>
                    <span className="rioglyph text-size-16 width-20 margin-top-0" />
                    <span className={'align-self-start'}>
                        <FormattedMessage id={'batteryService.stateOfHealth.availableAfterCollecting.reason'} />
                    </span>
                </div>
            </div>
        </div>
    );
};

export default SohBanner;
