import ContentLoader from '@rio-cloud/rio-uikit/ContentLoader';
import OverlayTrigger from '@rio-cloud/rio-uikit/OverlayTrigger';
import Popover from '@rio-cloud/rio-uikit/Popover';
import { useCallback } from 'react';
import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl';
import { useMatch } from 'react-router';
import { AnalyticsUtils } from '../../../configuration/setup/googleAnalytics';
import InternalErrorState from '../../app/InternalErrorState';
import { getDaysInYearsAndMonths } from '../utils/DateUtils';
import { isNumeric } from '../utils/NumberUtils';
import SohBanner from '../SohBanner';
import { useFetchBatteryLevelsQuery, useFetchProductForAssetQuery } from '../api/batterySlice';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import Button from '@rio-cloud/rio-uikit/Button';
import { BATTERY_SERVICE_OPEN_REMOTE_DIALOG, sendMessage } from '../../app/MessageHandler';
import { useAppSelector } from '../../../configuration/setup/hooks';
import {
    FT_HISTORICAL_BATTERY_CONDITIONS,
    isFeatureEnabled,
} from '../../../configuration/featureToggle/featureToggleSlice';
import { Criticality, SidebarStatusBar } from './SidebarStatusBar';
import {
    CollectingDataBanner,
    CRITICAL,
    LOW_MILEAGE,
    NO_DATA,
    NoData,
    OK,
    WARNING,
} from '../../batteryOverview/utils/BatteryDataUtils';
import ExpanderPanel from '@rio-cloud/rio-uikit/components/expander/ExpanderPanel';

const tooltipWrapperClassNames =
    'max-width-300 bg-white text-color-black display-flex flex-column align-items-start margin-5';
const tooltipSpacerClassNames =
    'margin-0 margin-bottom-5 border border-color-blue border-top-only border-width-5 width-100pct';
const tooltipMarginClassName = 'margin-left-5';

const marginTopBottom10 = 'margin-top-10 margin-bottom-10';

const LoadingScreen = () => {
    return (
        <>
            <div className="col-xs-12 col-sm-6 col-md-4 display-flex flex-column">
                <ContentLoader height={35} className={marginTopBottom10} />
                <ContentLoader height={100} width={'100%'} className={marginTopBottom10} />
                <ContentLoader height={100} width={'100%'} className={marginTopBottom10} />
            </div>
        </>
    );
};

const BatterySidebar = () => {
    const intl = useIntl();
    const assetId = useMatch('/:assetId')?.params.assetId;
    const { isLoading, isError, error, data: batteryLevel, refetch } = useFetchBatteryLevelsQuery(assetId!!);
    const { isLoading: isLoadingProduct, data: products } = useFetchProductForAssetQuery(assetId!!);

    const isGraphFeatureToggleEnable = useAppSelector(isFeatureEnabled(FT_HISTORICAL_BATTERY_CONDITIONS));

    if (isLoading || isLoadingProduct) {
        return <LoadingScreen />;
    }

    if (isError) {
        return (
            <div>
                <h6 className="text-uppercase text-color-dark margin-top-20">
                    <FormattedMessage id={'batteryService.batterySidebar.title'} />
                </h6>
                <InternalErrorState
                    errorCode={(error as FetchBaseQueryError).status as number}
                    reloadTriggerFunction={refetch}
                    isCustomerFrontend
                />
            </div>
        );
    }

    AnalyticsUtils.gaPush({
        event: 'show_electric_information',
        parameters: {
            element_name: 'BatterySidebar',
            component_name: 'MainNavigation',
            flow_name: 'view_customer_battery_health_data',
            trigger: 'visibility',
        },
        userProps: {},
    });

    const systemStateOfHealthStatus =
        batteryLevel?.health_data?.system.state_of_health_status?.toUpperCase() || NO_DATA;
    const sohPercentage = batteryLevel?.health_data?.system.state_of_health_percentage;
    const mileageValue = batteryLevel?.health_data?.system.mileage;
    const dischargingEnergyThroughput = batteryLevel?.health_data?.system.discharging_energy_throughput;
    const usage = getDaysInYearsAndMonths(batteryLevel?.health_data?.system.usage_days, intl);
    const KWH = ' kWh';

    return (
        <>
            <h6 className="text-uppercase text-color-dark margin-top-20">
                <FormattedMessage id={'batteryService.batterySidebar.title'} />
            </h6>
            {[OK, WARNING, CRITICAL].includes(systemStateOfHealthStatus) ? (
                <>
                    <ExpanderPanel
                        title={
                            <ExpanderTitle
                                percentage={sohPercentage}
                                systemStateOfHealthStatus={systemStateOfHealthStatus}
                                assetId={assetId!!}
                            />
                        }
                        titleClassName="width-100pct"
                        headerClassName="width-100pct padding-left-0 padding-top-0 padding-bottom-0"
                        bodyClassName={'bg-white'}
                        className={'border-style-solid border-width-1 border-color-light'}
                        onEntered={registerEventGA}
                        open={systemStateOfHealthStatus === WARNING || systemStateOfHealthStatus === CRITICAL}
                    >
                        <div className={'panel-container'}>
                            <div className={'panel-body padding-0'}>
                                <div className="display-grid detail">
                                    <BatteryDetailsRow
                                        name="batteryService.totalMileage"
                                        value={mileageValue}
                                        unit=" km"
                                    />
                                    <BatteryDetailsRow name="batteryService.batteryLifetime" value={usage} unit="" />
                                    <BatteryDetailsRow
                                        name="batteryService.dischargedEnergyThroughput"
                                        value={dischargingEnergyThroughput}
                                        unit={KWH}
                                        tooltipMsg="batteryService.dischargedEnergyThroughput.tooltip.body"
                                    />
                                </div>
                            </div>
                        </div>
                    </ExpanderPanel>
                    <Recommendation systemStateOfHealthStatus={systemStateOfHealthStatus} />
                </>
            ) : (
                <div className={'border-style-solid border-width-1 border-color-light panel-container bg-white'}>
                    <ExpanderTitle
                        percentage={sohPercentage}
                        systemStateOfHealthStatus={systemStateOfHealthStatus}
                        assetId={assetId!!}
                    />
                    <div className={'panel-body'}>
                        <div className="display-grid detail">
                            <BatteryDetailsRow name="batteryService.totalMileage" value={mileageValue} unit=" km" />
                            <BatteryDetailsRow name="batteryService.batteryLifetime" value={usage} unit="" />
                            <BatteryDetailsRow
                                name="batteryService.dischargedEnergyThroughput"
                                value={dischargingEnergyThroughput}
                                unit={KWH}
                                tooltipMsg="batteryService.dischargedEnergyThroughput.tooltip.body"
                            />
                        </div>
                    </div>
                </div>
            )}

            <div className={'margin-y-5'} />

            {systemStateOfHealthStatus === LOW_MILEAGE && <SohBanner />}

            {isGraphFeatureToggleEnable &&
                products !== undefined &&
                products.booked_products.includes('SERVICE_CARE_M') && (
                    <div className="display-flex justify-content-between gap-20 margin-bottom-10">
                        <div className="flex-1-1">
                            <div className="display-flex justify-content-between">
                                <Button
                                    onClick={() => {
                                        sendMessage({
                                            type: BATTERY_SERVICE_OPEN_REMOTE_DIALOG,
                                            payload: {
                                                asset_id: assetId,
                                            },
                                        });
                                    }}
                                    bsStyle={Button.PRIMARY}
                                    variant={Button.VARIANT_LINK}
                                    className={'text-left'}
                                >
                                    <span className="rioglyph rioglyph-line-chart" />
                                    <span>
                                        <FormattedMessage id="batteryService.batteryAging" />
                                    </span>
                                </Button>
                            </div>
                        </div>
                    </div>
                )}
        </>
    );
};
type BatteryDetailRowType = {
    name: string;
    value: number | string | undefined;
    unit: string;
    tooltipMsg?: string | undefined;
};

const BatteryDetailsRow = ({ name, value, unit, tooltipMsg }: BatteryDetailRowType) => {
    const rowTooltip = useCallback(
        (title: string, body: string) => (
            <Popover id={'axleinfo-popover'} className={tooltipMarginClassName} placement={'auto'}>
                <div className={tooltipWrapperClassNames}>
                    <h6>
                        <FormattedMessage id={title} />
                    </h6>
                    <hr className={tooltipSpacerClassNames} />
                    <span>
                        <FormattedMessage id={body} />
                    </span>
                </div>
            </Popover>
        ),
        [],
    );
    return (
        <div className={'row'}>
            <div className={'col-6 text-color-darkest font-300'}>
                <span>
                    <FormattedMessage id={name} />
                </span>
                {tooltipMsg !== undefined && (
                    <span className={tooltipMarginClassName}>
                        <OverlayTrigger overlay={rowTooltip(name, tooltipMsg)} placement={'right'}>
                            <span className="rioglyph rioglyph-info-sign margin-left-5 text-color-dark" />
                        </OverlayTrigger>
                    </span>
                )}
            </div>
            {value && (
                <div className="col-6 text-left">
                    <span>
                        {(isNumeric(value) && <FormattedNumber value={value} />) || value}
                        {unit}
                    </span>
                </div>
            )}
            {!value && (
                <div className="col-6 text-left text-color-gray">
                    <span>
                        <FormattedMessage id="batteryService.noData" />
                    </span>
                </div>
            )}
        </div>
    );
};

type ExpanderTitleType = {
    systemStateOfHealthStatus: string;
    percentage: number | undefined;
    assetId: string;
};

const ExpanderTitle = ({ systemStateOfHealthStatus, percentage }: ExpanderTitleType) => {
    const rioglyphOk = 'rioglyph ok';
    const hasValidSohStatus = [CRITICAL, OK, WARNING].includes(systemStateOfHealthStatus);

    const sohTooltip = useCallback(() => {
        const tooltipBodyId = 'batteryService.stateOfHealth.tooltip.' + systemStateOfHealthStatus.toLowerCase();
        const tooltipNoData = 'batteryService.stateOfHealth.tooltip.no_data';

        const renderTooltipContent = () => (
            <>
                {systemStateOfHealthStatus !== LOW_MILEAGE && (
                    <span className="margin-bottom-10">
                        <FormattedMessage
                            id={hasValidSohStatus ? tooltipBodyId : tooltipNoData}
                            values={{
                                b: (chunks) => <strong>{chunks}</strong>,
                            }}
                        />
                    </span>
                )}
                {(systemStateOfHealthStatus === CRITICAL || systemStateOfHealthStatus === WARNING) && (
                    <ul className="margin-bottom-10">
                        <li>
                            <FormattedMessage id="batteryService.batteryStateOfHealth.tooltip.body" />
                        </li>
                        <li>
                            <FormattedMessage id="batteryService.batteryStateOfHealth.tooltip.body2" />
                        </li>
                    </ul>
                )}
            </>
        );

        return (
            <Popover className={tooltipMarginClassName} placement={'auto'} id={'soh-popover'}>
                <div className={tooltipWrapperClassNames}>
                    <h6>
                        <FormattedMessage id="batteryService.stateOfHealth" />
                    </h6>
                    <hr className={tooltipSpacerClassNames} />
                    {renderTooltipContent()}
                    <span>
                        <FormattedMessage
                            id="batteryService.stateOfHealth.tooltip.information"
                            values={{
                                b: (chunks) => <strong>{chunks}</strong>,
                            }}
                        />
                    </span>
                </div>
            </Popover>
        );
    }, [systemStateOfHealthStatus]);

    let sohStatusClasses: string;
    let sohCriticality: Criticality;

    switch (systemStateOfHealthStatus) {
        case CRITICAL:
            sohStatusClasses = 'rioglyph rioglyph-error-sign text-color-danger margin-right-10';
            sohCriticality = Criticality.Critical;
            break;
        case WARNING:
            sohStatusClasses = 'rioglyph rioglyph-warning-sign text-color-warning margin-right-10';
            sohCriticality = Criticality.Warning;
            break;
        case NO_DATA:
            sohStatusClasses = rioglyphOk;
            sohCriticality = Criticality.Unknown;
            break;
        default:
            sohStatusClasses = rioglyphOk;
            sohCriticality = Criticality.Normal;
    }

    const getCriticality = () => {
        if (hasValidSohStatus) {
            if (sohCriticality === Criticality.Critical) return 'danger';
            else return sohCriticality;
        } else return Criticality.Unknown;
    };

    const titleClasses = 'width-50pct margin-0';
    const containerTitleClassNames = `display-flex list-group-item-${getCriticality()} 
    text-color-darkest list-group-item rounded-none border-none`;

    const renderSoHContent = () => {
        if (hasValidSohStatus)
            return (
                <div className={'width-50pct'}>
                    <SidebarStatusBar percentage={percentage} criticality={sohCriticality} />
                </div>
            );
        else if (systemStateOfHealthStatus === LOW_MILEAGE) return <CollectingDataBanner textSize={14} />;
        else return <NoData textSize={14} />;
    };

    return (
        <div className={containerTitleClassNames}>
            <div className={titleClasses}>
                <div className={'text-color-darkest'}>
                    <span className={hasValidSohStatus ? sohStatusClasses : rioglyphOk} />
                    <span>
                        <FormattedMessage id={'batteryService.stateOfHealth'} />
                    </span>

                    <span className={'margin-left-5 text-color-dark'}>
                        <OverlayTrigger overlay={sohTooltip()} placement={'right'}>
                            <span className="rioglyph rioglyph-info-sign margin-left-5" />
                        </OverlayTrigger>
                    </span>
                </div>
            </div>
            {renderSoHContent()}
        </div>
    );
};

const Recommendation = ({ systemStateOfHealthStatus }: { systemStateOfHealthStatus: string }) => {
    if (![WARNING, CRITICAL].includes(systemStateOfHealthStatus)) {
        return <></>;
    }

    const className = systemStateOfHealthStatus.toLowerCase();
    return (
        <div className={`panel panel-default border-color-${className === 'critical' ? 'danger' : className}`}>
            <div className={`soh-bg-${className}`}>
                <div className="panel-body">
                    <div className="text-color-black">
                        <h5>
                            <span className={`rioglyph rioglyph-info-sign margin-right-5 text-color-${className}`} />
                            <FormattedMessage id="batteryService.stateOfHealth.recommendation.title" />
                        </h5>
                        <div className="margin-left-25">
                            <FormattedMessage
                                id={`batteryService.stateOfHealth.recommendation.${className}`}
                                values={{
                                    b: (chunks) => <strong>{chunks}</strong>,
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const registerEventGA = () => {
    AnalyticsUtils.gaPush({
        event: 'click_battery_sidebar_expander',
        parameters: {
            element_name: 'BatterySidebar',
            component_name: 'Expander',
            flow_name: 'view_customer_battery_health_data',
            trigger: 'click',
        },
        userProps: {},
    });
};

export default BatterySidebar;
